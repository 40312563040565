import { getFullOnboardingDataFromLocal } from '~/helpers/getFullOnboardingDataFromLocal';
import { ONBOARDING_STEPS_METADATA } from '~/constants/onboardingStepsMetadata';
import EVENTS from '~/constants/events';

export default defineNuxtRouteMiddleware(async (to) => {
  if (process.server) {
    return;
  }

  const { $trackEvent } = useNuxtApp();

  const inviteToken = to.query.token as string;
  if (inviteToken) {
    const onboardingStore = useOnboardingStore();
    onboardingStore.verifyingInvitation = true;
    const invitation = await getInvitation(inviteToken);
    if (invitation) {
      if (invitation.expiresAtTimeMs < Date.now()) {
        $trackEvent(EVENTS.ONBOARDING_FLOW_STARTED, {
          page_landed: 'expired_link',
        });
      }

      const localUserInviteEmail = onboardingStore.onboardingInviteEmail;

      const userId = invitation.cmId || invitation.uid;

      if (userId) {
        useIdentifyEvent(userId);
      }

      if (!localUserInviteEmail || localUserInviteEmail !== invitation.email) {
        onboardingStore.resetOnboardingData();
      }

      onboardingStore.saveInvitation(inviteToken, invitation.email);
    }
    onboardingStore.verifyingInvitation = false;
  }

  const onboardingDataFromLocal = getFullOnboardingDataFromLocal();

  if (!onboardingDataFromLocal?.data) {
    return;
  }

  const { isCompleted, incompleteStepId, data } = onboardingDataFromLocal;

  if (to.name === 'member-onboarding-start' && isCompleted) {
    $trackEvent(EVENTS.ONBOARDING_FLOW_STARTED, {
      page_landed: 'sign_up_sampler',
    });
    onNuxtReady(() => {
      navigateTo({ name: 'member-signup' });
    });
    return;
  }

  if (isCompleted) {
    return;
  }

  if (!incompleteStepId) {
    return;
  }

  const incompleteStepRoute = {
    name: 'member-onboarding-question',
    params: { id: incompleteStepId },
  };
  const incompleteStepRouteFullPath = `/members/onboarding/${incompleteStepId}`;

  if (to.name === 'member-signup' && !isCompleted) {
    $trackEvent(EVENTS.ONBOARDING_FLOW_STARTED, {
      page_landed: 'survey_step_sampler_onboarding',
    });
    onNuxtReady(() => {
      navigateTo(incompleteStepRoute);
    });
    return;
  }

  if (
    to.name === 'member-onboarding-start' &&
    !isCompleted &&
    Object.keys(data).length > 0
  ) {
    $trackEvent(EVENTS.ONBOARDING_FLOW_STARTED, {
      page_landed: 'survey_step_sampler_onboarding',
    });
    onNuxtReady(() => {
      navigateTo(incompleteStepRoute);
    });
    return;
  }

  const currentStepId = to.params.id;

  if (!currentStepId) {
    return;
  }

  const uniqueOnboardingStepsIds = Array.from(
    new Set(ONBOARDING_STEPS_METADATA.map((step) => step.id))
  );

  const currentStepIndex = uniqueOnboardingStepsIds.findIndex(
    (item) => item === currentStepId
  );
  const incompleteStepIndex = uniqueOnboardingStepsIds.findIndex(
    (item) => item === incompleteStepId
  );

  if (
    to.fullPath !== incompleteStepRouteFullPath &&
    currentStepIndex > incompleteStepIndex
  ) {
    $trackEvent(EVENTS.ONBOARDING_FLOW_STARTED, {
      page_landed: 'survey_step_sampler_onboarding',
    });
    onNuxtReady(() => {
      navigateTo(incompleteStepRoute);
    });
  }
});
